import React, { Component } from 'react';  
const Blog = ({  classes }) => {  
    return (
        <div>
            <h1>Blogs</h1>
        </div>
    );
};

export default Blog;

