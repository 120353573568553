import React, { useState, useEffect, useRef } from 'react';

interface Props {
  title: string;
  children: React.ReactNode;
  open: boolean;
  onClose: Function;
  onConfirm: Function;
  closeOnClickBackDrop: Boolean;
  confirmBtn: string;
  formSubmit: Function;
}


export const Modal = (props: Props) => {
  const { onClose, title, children, onConfirm, confirmBtn = "Confirm", formSubmit } = props;
  const [open, setOpen] = useState(props.open);
  const wrapperRef = useRef();
  useEffect(() => {
    setOpen(props.open);
  }, [props]);
  
  useEffect(() => {
    if (props.closeOnClickBackDrop) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.closeOnClickBackDrop]);

  const handleClickOutside = e => {
    if (wrapperRef && !wrapperRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  
  const closeModal = () => {
    setOpen(false);
  };
    return (
        <div className={`transition-opacity fixed z-20 inset-0 overflow-y-auto ${(open) ? ``: `hidden`}`}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div ref={wrapperRef} className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex">
                  <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 pb-2 border-b" id="modal-headline">
                        {title}
                    </h3>
                    <div className="mt-2">
                        {children}
                      {/* <p className="text-sm text-gray-500">
                        Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button type="button" onClick={formSubmit} className="w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 bg-cyan-600 text-base font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 sm:w-auto sm:text-sm">
                  {confirmBtn}
                </button>
                <button type="button" onClick={closeModal} className="mt-3 w-full inline-flex justify-center border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
    );
}